.text-overlay {
  position: absolute;
  height: 200px;
  width: 200px;
  background: rgba(0, 0, 0, 0.35);
  top: 8px;
  left: 8px;
  z-index: 1;
  color: white;
  border-radius: 100%;
  clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0% 100%);
}