.text-gradient {
  background: -webkit-linear-gradient(left, #00a7e2, #0056b8);
  background: -o-linear-gradient(right, #00a7e2, #0056b8);
  background: -moz-linear-gradient(right, #00a7e2, #0056b8);
  background: linear-gradient(to right, #00a7e2, #0056b8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu-items {
  -webkit-transition: color 1s; /* For Safari 3.0 to 6.0 */
  transition: color 1s; /* For modern browsers */
}

.menu-items:hover {
  color: #0084ff;
}

.no-hover-effect:hover {
  background-color: #ffffff !important;
}
