@media only screen and (max-width: 1920px) {
  .container-class {
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: url(../../assets/images/wave.svg);
    background-size: 2160px auto;
    background-repeat: no-repeat;
    background-color: #f3f7fd;
    background-position-y: calc(100% - 189px);
    z-index: 1;
  }
}

@media only screen and (min-width: 1920px) {
  .container-class {
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: url(../../assets/images/wave.svg);
    background-size: 4096px auto;
    background-repeat: no-repeat;
    background-color: #f3f7fd;
    background-position-y: calc(100% - 189px);
    z-index: 1;
  }
}

.card {
  position: absolute;
  width: 100%;
  height: calc(100% - 190px);
  background-color: rgba(243, 247, 253, 0);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: 0;
}
