@font-face {
  font-family: "LamaRounded";
  src: local("LamaRounded-Black"),
    url(../assets/fonts/LamaRounded-Black.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  max-width: 100vw;
  overflow-x: hidden;
}
