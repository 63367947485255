.Mui-selected {
  background-color: #ffffff !important;
  color: black !important;
}

.MuiPaginationItem-root:not(.Mui-selected) {
  color: #ffffff !important;
}

.MuiCard-root .arrow {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.75s linear;
}

.MuiCard-root:hover .arrow {
  visibility: visible;
  opacity: 1;
}

.name-filter-field .MuiInputBase-root {
  padding-right: 0 !important;
}

@media only screen and (max-width: 1920px) {
  .layoutMinHeight {
    min-height: 525px;
  }
}

@media only screen and (min-width: 1920px) {
  .layoutMinHeight {
    min-height: 1050px;
  }
}
