.MuiCardContent-root:last-child.language-card {
  padding-bottom: 12px !important;
}

.value-field .MuiInputBase-root {
  padding-right: 0 !important;
}

.value-field-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.MuiFilledInput-root.MuiInputBase-multiline {
  padding: 0 !important;
}

.MuiInputBase-multiline:not(.MuiFilledInput-root) {
  padding: 0 !important;
  margin-top: 24px !important;
}

.MuiFilledInput-input.MuiInputBase-inputMultiline {
  margin-top: 1.25rem !important;
  margin-left: 0.7rem !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  z-index: 0;
}

@media only screen and (max-width: 1920px) {
  .layoutMinHeight {
    min-height: 600px;
  }
}

@media only screen and (min-width: 1920px) {
  .layoutMinHeight {
    min-height: 1200px;
  }
}