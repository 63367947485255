.Mui-selected {
  background-color: #ffffff !important;
  color: black !important;
}

.MuiPaginationItem-root:not(.Mui-selected) {
  color: #ffffff !important;
}

.MuiAccordion-root::before {
  content: none !important;
}

.accordion-border-radius {
  border-radius: 10px !important;
}

@media only screen and (max-width: 1920px) {
  .layoutMinHeight {
    min-height: 525px;
  }
}

@media only screen and (min-width: 1920px) {
  .layoutMinHeight {
    min-height: 1050px;
  }
}