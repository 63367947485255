@media screen and (max-width: 960px) {
  .mobile-grid {
    margin-top: 2rem !important;
  }
}

.apple-button-hover:hover {
  background-color: #263238 !important;
}

.google-button-hover:hover {
  background-color: #cb2027e6 !important;
}

.text-gradient {
  background: -webkit-linear-gradient(right, #00a7e2, #0056b8);
  background: -o-linear-gradient(right, #00a7e2, #0056b8);
  background: -moz-linear-gradient(right, #00a7e2, #0056b8);
  background: linear-gradient(to right, #00a7e2, #0056b8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
