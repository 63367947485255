.email-link {
  color: #ffffff !important;
}

.text-gradient {
  background: -webkit-linear-gradient(left, #00a7e2, #0056b8);
  background: -o-linear-gradient(right, #00a7e2, #0056b8);
  background: -moz-linear-gradient(right, #00a7e2, #0056b8);
  background: linear-gradient(to right, #00a7e2, #0056b8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
